<template>
  <div>
    <user-info></user-info>
    <div class="center-main">
      <div class="menu-wrap">
        <ul class="menu-list">
          <li :class="status == index ? 'list-item active': 'list-item'" v-for="(item, index) in menuList" :key="index" @click="goPage(item.path)">{{ item.name}}</li>
        </ul>
      </div>
      <div class="content-wrap">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/components/UserInfo'
export default {
  name: 'user-center',
  components: {
    UserInfo
  },
  data () {
    return {
      status: 0,
      menuList: [
        {
          name: '流量卡',
          path: '/usercenter/card'
        },
        {
          name: '充值记录',
          path: '/usercenter/record'
        }
      ]

    }
  },
  created () {
    this.changeNav(this.$route.name)
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    changeNav (name) {
      switch (name) {
        case 'user-card' :
          this.status = 0
          break
        case 'user-record' :
          this.status = 1
          break
        default :
          this.status = 0
          break
      }
    },
    goPage (url) {
      this.$router.replace(url)
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.changeNav(to.name)
    next()
  },
  activated () {
    this.changeNav(this.$route.name)
  }
}
</script>

<style lang="scss" scoped>
  .center-main{
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top:6.125rem;
    bottom: 0;
    display: flex;
    z-index: 5;
  }
  .menu-wrap {
    width: 5.625rem;
    height: 100%;
    overflow: hidden;
    overflow-y:auto;
  }
  .menu-list{
    padding: 1rem 0;
    margin: 0;
    .list-item {
      height: 2.5rem;
      line-height: 2.5rem;
      position: relative;
      padding-left: 0.75rem;
      font-size: 0.875rem;
      color:#666;
      &::before{
        width: 0.125rem;
        height: 1rem;
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
        background-color: #0177F8;
      }
      &.active {
        background-color: #fff;
        box-shadow: -1px 0px 9px rgba(0, 0, 0, 0.06);
        &::before{
          content: '';
        }
       }
    }
  }
  .content-wrap{
    height: 100%;
    overflow: hidden;
    overflow-y:auto;
    flex: 1;
    background-color: #fff;
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.06);
  }
</style>
