<template>
  <div :class="'user-info-bar' + ' ' + classname">
    <div class="user-wrap">
        <div class="user-info">
          <div class="user-pic">
            <img :src="userData.pic ? pic : require('@/assets/images/pic_user_default.png')" />
          </div>
          <span>{{ userData.cellphone }}</span>
        </div>
        <div class="op-wrap">
          <button
            type="button"
            :class="userData.activation ? 'btn-op btn-ok' : 'btn-op'"
          >
            {{ userData.activation ? "已激活" : "未激活" }}
          </button>
          <button type="button" :class="userData.isreal ? 'btn-op btn-ok' : 'btn-op'">
            {{ userData.isreal ? "已实名" : "未实名" }}
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'user-info',
  props: {
    classname: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  computed: {
    ...mapState({
      userData: state => state.user.userData
    })
  }
}
</script>

<style lang="scss" scoped>
  .user-info-bar {
    padding: 1.875rem 1.25rem 0;
    box-sizing: border-box;
    background: linear-gradient(93.29deg, #00a5ff 7.28%, #0071f6 97.31%);
    height: 6.125rem;
  }
  .user-wrap {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user-info {
    font-size: 0.875rem;
    color: #fff;
    display: flex;
    height: 100%;
    align-items: center;
  }
  .user-pic {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #fff;
    overflow: hidden;
    margin-right: 0.7rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .op-wrap {
    display: flex;
    .btn-op {
      display: block;
      padding: 0 5px;
      height: 1.125rem;
      border-radius: 1.125rem;
      font-size: 0.75rem;
      background-color: #fff;
      color: #ff6b00;
      &:nth-child(1) {
        margin-right: 1.25rem;
      }
      &.btn-ok {
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
      }
    }
  }
}

</style>
